//@flow
import React, { useState } from 'react'
import { iubendaID, relativeRoot } from '../../common'
import { Link, useHistory } from 'react-router-dom'
import { useUnsubscribe } from '../../effects/unsubscribe'
import { useSignIn } from '../../effects/signIn'
import { useTranslation } from 'react-i18next'
import useLanguages from '../../effects/languages'
import { SubscriberAtom } from '../../atoms/Subscriber'

import { StepsAtom, goToStep } from '../../atoms/Steps'
import { PaymentAtom } from '../../atoms/Payment'

import { useAction, useAtom } from '@reatom/react'

import { gtagEvent } from '../../utils/gtag'
import { UnsubExperiment } from '../../enums/UnsubEnum'
import { useSkipStepExperiment, useUnsubExperiment } from '../../contexts/ExperimentsContext'
import { SkipStepExperimentEnum } from '../../enums/SkipStepExperimentEnum'
import { StepsSkipExperimentAtom, goToStep as goToStepExperiment } from '../../atoms/StepsSkipExperiment'

const appStoreLink = 'https://itunes.apple.com/app/apple-store/id981437721?pt=1076586&ct=bm_other&mt=8'
const googleStoreLink = 'https://play.google.com/store/apps/details?id=com.luxand.app'

// const rootPath = window.location.pathname.toString().includes(`/${i18n.language}/`)
//   ? `${relativeRoot}/${i18n.language}`
//   : `${relativeRoot}`;

export const Header = (props) => {
  const { languages, seoLandings, languageChangeHandler } = useLanguages()
  const { t, i18n } = useTranslation()
  const rootPath = i18n.language === 'en' ? `/` : `/${i18n.language}/`
  const unsubscribeHandler = useUnsubscribe()
  const signInHandler = useSignIn()
  const signInPopUp = signInHandler.signInPopUp()
  const otpPopUp = signInHandler.OTPPopUp()
  const subscriberState = useAtom(SubscriberAtom)
  const history = useHistory()

  const steps = useAtom(StepsAtom)
  const stepsExperiment = useAtom(StepsSkipExperimentAtom)
  const paymentState = useAtom(PaymentAtom)
  const skipStepExperiment = useSkipStepExperiment()

  let showPayScreen = steps.currentStepId === 4 && !paymentState.payed
  let showResultScreen = steps.currentStepId === 4 && paymentState.payed
  if (SkipStepExperimentEnum.skip === skipStepExperiment) {
    showPayScreen = stepsExperiment.currentStepId === 3 && !paymentState.payed
    showResultScreen = stepsExperiment.currentStepId === 3 && paymentState.payed
  }

  const isSubscriberLoaded = subscriberState.subscriber !== null
  const paymentTypeLoaded = paymentState.weeklySub !== null
    || paymentState.oneTimePay !== null
    || paymentState.monthlySub !== null

  const showPreparedPayScreen = isSubscriberLoaded && showPayScreen && paymentTypeLoaded

  const hideMobileMenu = !showPreparedPayScreen && showResultScreen
  const mobileMenuClasses = ['mobile-menu-toggle']
  if (hideMobileMenu) {
    mobileMenuClasses.push('hidden')
  }

  const goToFirstStep = useAction(_ => goToStep(1), [])
  const goToFirstStepExperiment = useAction(_=>goToStepExperiment(1),[])

  const [signIn, setSignIn] = useState(() => {
  })

  React.useEffect(() => {
    setSignIn(() => {
      return signInHandler.showProfilePopUp
    })
    if (subscriberState.loaded) {
      if (subscriberState.subscriber && subscriberState.subscriber.email) {
        setSignIn(() => {
          return history.push
        })
      }
    }
  }, [subscriberState.subscriber])

  React.useEffect(() => {
    const closeMobileMenu = (e) => {
      if (e.target.closest('.mobile-menu-wrap') !== null) {
        const menuToggle = document.querySelector('.mobile-menu-toggle.active')
        menuToggle.click()
      }
    }
    document.addEventListener('click', closeMobileMenu)
    return () => {
      document.removeEventListener('click', closeMobileMenu)
    }
  })

  // let unsubName = t(`header.Profile`);
  const unsubExperiment = useUnsubExperiment()
  const unsubName = t(`experiment.unsub.${unsubExperiment}`)
  const currYear = (new Date()).getFullYear()

  return (
    <>
      {signInPopUp}
      {otpPopUp}
      <header className="header"
              id="header">
        <div className="container">
          <Link to={rootPath}
                className={'logo'}
                title={t('header.Baby Face Generator')}
                onClick={()=>{
                  if(SkipStepExperimentEnum.dontSkip === skipStepExperiment) {
                    goToFirstStep()
                  }
                  if(SkipStepExperimentEnum.skip === skipStepExperiment) {
                    goToFirstStepExperiment()
                  }
                }}

          >
            <img src="/img/logo@2x.png"
                 alt={t('header.Baby Face Generator')}
            />
          </Link>
          <h1 className="site-desc">{t('header.What Will Your Baby Look Like?')}</h1>
          <nav className="app-nav">
            <ul className="app-menu">
              <li>
                <a onClick={signInHandler.showSignInPopUp}
                   className="btn inverse"
                >
                  {t('header.Sign In')}<span className="small">{t('header.with your email')}</span>
                </a>
              </li>
            </ul>
          </nav>

          <div className="mobile-menu-wrap">
            <div className="mobile-menu-box">
              <div className="mobile-menu-body">
                <nav className="app-nav mobile">
                  <ul className="app-menu">
                    <span
                      className="app-desctiption"

                    >
                      BabyFaceGenerator analyzes up to 70 facial features of two partners to generate the face of a future baby. BabyFaceGenerator is based on AI technology, however genetics are far more complex than that. Use this site for your entertainment only.
                    </span>
                    {/* <li>
                      <a href={appStoreLink}
                         className="btn inverse with-icon apple"
                         title={t('header.Available on Apple store')}>
                        <span className="bm_icon_apple"/>
                        <span className="small">{t('header.Available on')}</span>{t('header.Apple store')}</a>
                    </li>
                    <li>
                      <a href={googleStoreLink}
                         className="btn inverse with-icon"
                         title={t('header.Android App on Google Play')}>
                        <span className="bm_icon_google-play"/>
                        <span className="small">{t('header.Android App on')}</span>{t('header.Google Play')}</a>
                    </li> */}

                    <li>
                      <div className="pointer-description-block">
                        <span className="pointer-description">
                          Available in different languages:<br/>
                        </span>
                      </div>
                      {(languages.map((lang, idx) => {
                        return <div className="pointer-wrapper"
                                    key={`top_lang_${lang.url}`}>
                          <a
                            href={lang.url === 'en' ? '/' : `/${lang.url}/`}
                            className={`pointer`}
                            onClick={(e) => {
                              e.preventDefault()
                              languageChangeHandler(lang.url)
                            }}>{lang.name.toUpperCase()} {idx !== languages.length - 1 && (
                            <span style={{ fontWeight: '100' }}><br/></span>)}
                          </a>
                        </div>
                      }))}
                    </li>
                    {seoLandings.length > 0 && <li>
                      {(seoLandings.map((landing, idx) => {
                        return <a key={`land_${idx}`}
                                  href={`/${landing.url}/`}
                                  className={`pointer`}
                                  onClick={(e) => {
                                    e.preventDefault()
                                    languageChangeHandler(landing.url)
                                  }}>{landing.name.toUpperCase()} {idx !== seoLandings.length - 1 && (
                          <span style={{ fontWeight: '100' }}>&nbsp;|&nbsp;</span>)} </a>
                      }))}
                    </li>}
                  </ul>
                </nav>
                <nav className="mobile-menu">
                  <ul>
                    <li>
                      <Link to={`${relativeRoot}/`}>{t('header.Home')}</Link>
                    </li>
                    <li>
                      <a onClick={() => {
                        signInHandler.showSignInPopUp()
                      }}
                         className="pointer">
                        {t('header.Sign In mobile')}
                      </a>
                    </li>
                    <li>
                      <Link to={`${relativeRoot}/about-us/`}
                      >{t('header.About Us')}</Link>
                    </li>
                    <li>
                      <Link to={`${relativeRoot}/contact-us/`}
                      >{t('header.Contact Us')}</Link>
                    </li>
                    <li>
                      <Link to={`${relativeRoot}/feedback/`}>{t('header.Feedback')}</Link>
                    </li>
                    <li>
                      <a target={`_blank`}
                         href={`https://www.iubenda.com/privacy-policy/${iubendaID}`}
                      >{t('footer.Privacy Policy')}</a>
                    </li>
                    <li>
                      <Link to={`${relativeRoot}/terms-of-service/`}>{t('header.Terms of Service')}</Link>
                    </li>
                    <li>
                      <a target={`_blank`}
                         href={`https://www.iubenda.com/privacy-policy/${iubendaID}/cookie-policy`}
                      >{t('footer.Cookie Policy')}</a>
                    </li>
                    <li>
                      <a onClick={() => {
                        signIn('/profile')
                        gtagEvent({
                          action: 'manage account click',
                          category: 'manage account',
                          label: 'manage-mobile-menu'
                        })
                      }}
                         className="pointer">
                        {unsubName ? unsubName : t('header.Profile')}
                      </a>
                      {/*<a className={"pointer"}*/}
                      {/*   title={t('header.Cancel Subscription')}*/}
                      {/*   onClick={()=>{unsubscribeHandler.handleUnsubscribe('menu');}}*/}
                      {/*>{t('header.Cancel Subscription')}</a>*/}
                    </li>
                    <li>
                      <a href="#"
                         onClick={(e) => {
                           e.preventDefault()
                         }}
                         className="iubenda-ccpa-opt-out"
                      >{t('header.Do Not Sell My Personal Information')}</a>
                    </li>
                    <li>
                      <a href="#" className="iubenda-cs-uspr-link">Notice at Collection</a>
                    </li>
                    <li>
                      <a href='#' className='iubenda-cs-preferences-link'> <img style={{width: '2rem'}}
                                                                                src="data:image/svg+xml;charset=UTF-8,%3csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 30 14' style='enable-background:new 0 0 30 14;' xml:space='preserve'%3e%3cstyle type='text/css'%3e .st0%7bfill-rule:evenodd;clip-rule:evenodd;fill:%23FFFFFF;%7d .st1%7bfill-rule:evenodd;clip-rule:evenodd;fill:%230066FF;%7d .st2%7bfill:%23FFFFFF;%7d .st3%7bfill:%230066FF;%7d %3c/style%3e%3cg%3e%3cg id='final---dec.11-2020_1_'%3e%3cg id='_x30_208-our-toggle_2_' transform='translate(-1275.000000, -200.000000)'%3e%3cg id='Final-Copy-2_2_' transform='translate(1275.000000, 200.000000)'%3e%3cpath class='st0' d='M7.4,12.8h6.8l3.1-11.6H7.4C4.2,1.2,1.6,3.8,1.6,7S4.2,12.8,7.4,12.8z'/%3e%3c/g%3e%3c/g%3e%3c/g%3e%3cg id='final---dec.11-2020'%3e%3cg id='_x30_208-our-toggle' transform='translate(-1275.000000, -200.000000)'%3e%3cg id='Final-Copy-2' transform='translate(1275.000000, 200.000000)'%3e%3cpath class='st1' d='M22.6,0H7.4c-3.9,0-7,3.1-7,7s3.1,7,7,7h15.2c3.9,0,7-3.1,7-7S26.4,0,22.6,0z M1.6,7c0-3.2,2.6-5.8,5.8-5.8 h9.9l-3.1,11.6H7.4C4.2,12.8,1.6,10.2,1.6,7z'/%3e%3cpath id='x' class='st2' d='M24.6,4c0.2,0.2,0.2,0.6,0,0.8l0,0L22.5,7l2.2,2.2c0.2,0.2,0.2,0.6,0,0.8c-0.2,0.2-0.6,0.2-0.8,0 l0,0l-2.2-2.2L19.5,10c-0.2,0.2-0.6,0.2-0.8,0c-0.2-0.2-0.2-0.6,0-0.8l0,0L20.8,7l-2.2-2.2c-0.2-0.2-0.2-0.6,0-0.8 c0.2-0.2,0.6-0.2,0.8,0l0,0l2.2,2.2L23.8,4C24,3.8,24.4,3.8,24.6,4z'/%3e%3cpath id='y' class='st3' d='M12.7,4.1c0.2,0.2,0.3,0.6,0.1,0.8l0,0L8.6,9.8C8.5,9.9,8.4,10,8.3,10c-0.2,0.1-0.5,0.1-0.7-0.1l0,0 L5.4,7.7c-0.2-0.2-0.2-0.6,0-0.8c0.2-0.2,0.6-0.2,0.8,0l0,0L8,8.6l3.8-4.5C12,3.9,12.4,3.9,12.7,4.1z'/%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/svg%3e"
                                                                                alt="California Consumer Privacy Act (CCPA) Opt-Out Icon"/>Your
                        Privacy Choices</a>
                    </li>
                    <li></li>
                  </ul>
                </nav>
              </div>
              <div className="mobile-menu-footer">
                <p className="copyright">{t('header.Copyright')} © {currYear}. {t('header.All rights received')}.</p>
              </div>
            </div>
          </div>

          <div className={mobileMenuClasses.join(' ')}>
            <span/>
          </div>
        </div>
      </header>
    </>
  )
}